<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        elevation="1"
        tile
        color="#135875"
        class="text-white"
      >
        Actions<v-icon right>mdi-menu-down</v-icon>
      </v-btn>
    </template>
    <v-list dense>
      <v-list-item @click="addNewApplication()"
        ><v-list-item-title>New Application</v-list-item-title></v-list-item
      >
      <v-list-item @click="addNewBulkApplication(true)" v-if="isDmAdminRolesChild"
        ><v-list-item-title
          >Bulk Upload Application via Excel</v-list-item-title
        ></v-list-item
      >
    </v-list>
  </v-menu>
</template>

<script>
export default {
  props: {
    isDmAdminRolesChild: Boolean,
  },
  emits: ["addNewApplication", "addNewBulkApplication"],
  methods: {
    addNewApplication() {
      this.$emit("addNewApplication");
    },
    addNewBulkApplication(val) {
      this.$emit("addNewBulkApplication", val);
    },
  },
};
</script>
