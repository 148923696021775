<template>
  <v-dialog
    v-model="createBulkApplicationDialog"
    width="auto"
    @click:outside="toggleCreateBulkApplicationDialog(false)"
  >
    <v-card>
      <v-toolbar :color="hrdcThemeColor" dark
        >Bulk Upload Applications
        <v-spacer></v-spacer>
        <v-menu
          offset-y
          :rounded="'lg'"
          transition="slide-y-transition"
          :max-height="'300'"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list dense>
            <v-list-item
              v-for="(action, index) in bulkActions"
              :key="`action_${index}`"
              link
              @click="executeAction(action.value)"
            >
              <v-list-item-title>{{ action.text }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar>

      <div class="pa-3">
        <v-col cols="12">
          <div>
            <u><b>Step 1</b></u>
          </div>
          Please download sample excel template & follow the instruction in
          order to upload bulk applications.
          <span class="text-danger">(*upper right corner)</span>
        </v-col>

        <hr />

        <v-col cols="12" class="pt-0">
          <div>
            <u><b>Step 2</b></u>
          </div>
          Select the event, copy & paste to
          <b>'Workshop / Event Session'</b> column:
        </v-col>

        <v-col
          cols="12"
          class="pt-0"
          v-for="(item, key) in eventLists"
          :key="`event_${key}`"
        >
          <v-btn-toggle>
            <v-btn
              class="btn-wrap-text"
              active-class="no-active"
              style="text-transform: none !important"
              @click="copyContentFromChild(item.text)"
            >
              {{ item.text }}
            </v-btn>
          </v-btn-toggle>
        </v-col>

        <hr />

        <v-col cols="12" class="py-0">
          <div>
            <u><b>Step 3</b></u>
          </div>
          <span
            >After filling up the excel, kindly upload to input fields
            below:</span
          >
          <v-file-input
            :prepend-icon="null"
            prepend-inner-icon="mdi-paperclip"
            dense
            filled
            small-chips
            label="Excel"
            accept=".xlsx, .xls"
            v-model="formData.bulkApplicationAttachment"
            :error-messages="errorField.bulkApplicationAttachment"
            :truncate-length="1000"
            class="pt-3"
            @change="readExcelDataFromChild()"
          ></v-file-input>
        </v-col>

        <hr />

        <v-col cols="12" class="pt-0">
          <div>
            <u><b>Step 4</b></u>
          </div>
          Upload bank account details attachments:
          <div
            v-for="(data, index) in excelDatas"
            :key="`excel_data_${index}`"
            v-show="!errorDetected"
          >
            <v-file-input
              v-if="data.isPaymentMade"
              :prepend-icon="null"
              prepend-inner-icon="mdi-paperclip"
              dense
              filled
              small-chips
              accept=".pdf, image/*"
              :label="`Group ${data.group} Bank Statement Header - ${data.companyName}`"
              v-model="formData.ssRefundBankAccAtch[index]"
              :error-messages="errorField.ssRefundBankAccAtch"
              :truncate-length="1000"
              @change="
                uploadSsBankAtchFromChild({
                  index,
                  grp: data.group,
                })
              "
            ></v-file-input>
          </div>
        </v-col>
      </div>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
          class="text-light green lighten-1"
          @click="importDataFromChild()"
        >
          Import
        </v-btn>

        <v-btn
          class="text-light red lighten-1"
          @click="toggleCreateBulkApplicationDialog(false)"
        >
          Cancel
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    createBulkApplicationDialog: Boolean,
    hrdcThemeColor: String,
    bulkActions: Array,
    eventLists: Array,
    serviceKey: String,
    formData: Object,
    errorField: Object,
    excelDatas: Array,
    errorDetected: Boolean,
  },
  emits: [
    "toggleCreateBulkApplicationDialog",
    "executeBulkAction",
    "copyContent",
    "readExcelData",
    "uploadSsBankAtch",
    "importData",
  ],
  methods: {
    toggleCreateBulkApplicationDialog(val) {
      this.$emit("toggleCreateBulkApplicationDialog", val);
    },
    executeAction(action) {
      this.$emit("executeBulkAction", action);
    },
    copyContentFromChild(item) {
      this.$emit("copyContent", item);
    },
    readExcelDataFromChild() {
      this.$emit("readExcelData");
    },
    uploadSsBankAtchFromChild(data) {
      this.$emit("uploadSsBankAtch", data);
    },
    importDataFromChild() {
      this.$emit("importData");
    },
  },
};
</script>
<style>
.v-btn--active.no-active::before {
  opacity: 0 !important;
}
</style>
